import React, { FC } from 'react';
import './Users.scss';

interface UsersProps {}

const Users: FC<UsersProps> = () => (
  <div className="Users">
    Users Component
  </div>
);

export default Users;
