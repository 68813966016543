import React, { FC } from 'react';
import './Home.scss';

interface HomeProps {}

const Home: FC<HomeProps> = () => (
  <div className="Home">
      <p className='mainPgf'>
          A <b>customer portal</b> is a website designed to give current customers access to services and information they need.
          It’s usually private and secure, requiring log-on. It may contain account information, payment information, case information,  account history, downloadable digital files, access to support mechanisms, and the means for customers to upload information.
          Essentially, a customer portal is a digital sharing mechanism and information center between a company or organization and its customers.
      </p>

      <div className='img'>
          <img src='/home.png' alt="Logo"/>
      </div>
  </div>
);

export default Home;
