import { faker } from '@faker-js/faker';
import {Customer, Report} from '../types';

const camelize = (str: string) => {
    return str.replace(/(^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

const minAge = 10;
const maxAge = 90;
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

let customerCounter = 1000;

const createCustomer = ({ id, isNew, newUntil }: { id: number, isNew: boolean, newUntil: Date | undefined }): Customer => {
    return {
        id,
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName(),
        email: faker.internet.email(),
        avatar: faker.image.avatar(),
        age: faker.datatype.number({
            min: minAge,
            max: maxAge
        }),
        address: {
            street: faker.address.street(),
            city: faker.address.city(),
            country: faker.address.country()
        },
        addedAt: faker.date.past(),
        isNew,
        newUntil
    };
};

export const customers: Array<Customer> = Array.from(Array(10)).map( _ => {

    const id = customerCounter++;

    const isNew = !!(id % 2);

    let newUntil;

    if (isNew) {
        newUntil = Math.random() < .8 ? tomorrow : yesterday;
    }

    return createCustomer({
        id,
        isNew,
        newUntil
    });

});

const customerHeaders = Object.keys(customers[0]).map(item => {
    return camelize(item);
});

let reportCounter = 3243;

export const reports: Array<Report> = [{
    id: reportCounter++,
    name: 'All customers',
    headers: customerHeaders,
    data: customers,
    isNew: true,
    newUntil: tomorrow,
}];

const reportsVariations = [{
    ageRange: [minAge, 30]
}, {
    ageRange: [31, 50],
    //isNew: true
}, {
    isNew: true,
    newUntil: yesterday,
    ageRange: [51, maxAge]
}];

for (const reportsVariation of reportsVariations) {

    const { isNew, newUntil, ageRange } = reportsVariation;
    const [min, max] = ageRange;

    reports.push({
        id: reportCounter++,
        name: `All customers from ${min} to ${max}`,
        headers: customerHeaders,
        data: customers.filter(item => item.age >= min && item.age <= max),
        isNew,
        newUntil
    })
}

export const currUser = {
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
}