import thatsnuSDK from '@thatsnu/browser-sdk';
import LoggerService from './LoggerService';
import {AppParams, ThatsnuSdk} from '@thatsnu/browser-sdk/dist/types';

export enum ThatsnuInitMethods {
    CDN,
    MODULE
}

interface IThatsnuService {
    sdk?: ThatsnuSdk
    init({ method }: { method: ThatsnuInitMethods }): Promise<void>,
    reset(): void
}

const initAsModule = async(props?: AppParams): Promise<ThatsnuSdk> => {
    await thatsnuSDK.init(props);
    return thatsnuSDK;
};

export enum THATSNU_GROUPS {
    SETTINGS = 'settings',
    CUSTOMERS = 'customers',
    REPORTS = 'reports'
}

const thatsnuService: IThatsnuService = {
    async init({ method }: { method: ThatsnuInitMethods }): Promise<void> {
        const sdkInitProps: AppParams = {
            //initialState: ['settings'],
            //debugTooltip: true,
            //defaultColor: '#454545',
            indicators: [{
                id: THATSNU_GROUPS.CUSTOMERS,
                text: 'New!',
                tooltipText: 'New features inside, Check it out!',
                tooltipStyles: {
                    'background-color': 'rgba(109, 65, 161, 1)',
                    'border-color': 'rgba(109, 65, 161, 1)',
                    color: 'white'
                }
            }, {
                id: THATSNU_GROUPS.REPORTS,
                //text: 'New!',
                tooltipText: 'Introducing new reporting system!',
                color: '#00f',
                className: 'tnuReportsIndicator',
                tooltipClassName: 'tnuReportsIndicatorTooltip'
            }, {
                id: THATSNU_GROUPS.SETTINGS,
            }, {
                id: `${THATSNU_GROUPS.CUSTOMERS}.item.*`,
                text: 'New!'
            }],
            onClick: (id: string): boolean => {
                LoggerService.debug(`Element ${id} clicked!`);

                const userState = this.sdk?.getState();
                LoggerService.debug(`User clicked: ${userState}`);

                return true;
            }
        };

        if (method === ThatsnuInitMethods.MODULE) {
            this.sdk = await initAsModule(sdkInitProps);

            // @ts-ignore
            window.thatsnuSdk = this.sdk;
        } else{
            // TODOO
        }
    },
    reset() {
        this.sdk?.resetState();
    }
};

export default thatsnuService;