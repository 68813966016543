import React, {FC, useEffect, useState} from 'react';
import './Report.scss';
import {useParams} from 'react-router-dom';
import LoggerService from '../../../services/LoggerService';
import ReportsService from '../../../services/ReportService';
import {Report as ReportType} from '../../../types';
import Spinner from '../../Shared/Spinner/Spinner';

interface ReportProps {
}

const Report: FC<ReportProps> = () => {
    const [loading, setLoading] = useState(true);
    const [report, setReport] = useState<ReportType>();

    const { reportId: reportIdParam } = useParams();
    const reportId = parseInt(reportIdParam || '0') || 0;

    useEffect(() => {
        ReportsService.getReport(reportId).then(report => {
            setReport(report);
        }).catch(e => {
            LoggerService.error(e);
        }).finally(() => {
            setLoading(false)
        });
    });

    return <div className="Report">
        {!loading ?
            <div>
                { !!report ?
                    <div>

                        <div className='reportHeader'>
                            <div className='nameCol'>{report.name}</div>
                            <div className='shareButtonCol'>
                                <button
                                    className='clickable'>
                                    Share!
                                </button>
                                <span data-tnu-id="reports.share"
                                      data-tnu-class-name='tnuShareReportIndicator'
                                      data-tnu-tooltip-text="New! share this report with others"></span>
                            </div>
                        </div>

                        <table className='table'>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Image</th>
                                <th>Age</th>
                                <th>Address</th>
                                <th>Added at</th>
                            </tr>
                            </thead>
                            <tbody>
                            {report.data.map((row) => {
                                return <tr className='customerRow' key={row.id}>
                                    <td>{row.id}</td>
                                    <td>{row.firstName}</td>
                                    <td>{row.lastName}</td>
                                    <td>{row.email}</td>
                                    <td>
                                        <img src={row.avatar} alt="Customer" className='userImage' />
                                    </td>
                                    <td>{row.age} years</td>
                                    <td>{row.address.street}, {row.address.city}, {row.address.country}</td>
                                    <td>{row.addedAt.toISOString()}</td>
                                </tr>;
                            })}
                            </tbody>
                        </table>
                    </div>

                    : <div>Invalid report</div>}
            </div> :
            <Spinner/>
        }
    </div>;
};

export default Report;
