import {customers} from '../mock/data';
import {Customer} from '../types';
import General from './GeneralService';

const delay = 250;

export default {
    async getAll(): Promise<Array<Customer>> {
        await General.wait(delay);
        return customers;
    },
    async getCustomer(id: number): Promise<Customer|undefined> {
        await General.wait(delay);
        return customers.find(item => item.id === id);
    }
}