import React, { FC } from 'react';
import './Icon.scss';

interface IconProps {
    icon: string,
    clickable?: boolean
}

const Icon: FC<IconProps> = (props) => {

    const classes = [props.icon];

    if (props.clickable) {
        classes.push('clickable');
    }

    return (<i className={'icon '+classes.join(' ')}></i>);

};

export default Icon;
