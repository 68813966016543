import {reports} from '../mock/data';
import {Report} from '../types';
import General from './GeneralService';

const delay = 250;

export default {
    async getAll(): Promise<Array<Report>> {
        await General.wait(delay);
        return reports;
    },
    async getReport(id: number): Promise<Report|undefined> {
        await General.wait(delay);
        return reports.find(item => item.id === id);
    }
}