import React, { FC } from 'react';
import './Settings.scss';
import thatsnuService from '../../services/ThatsnuService';

interface SettingsProps {}

const resetThatsnu = ()=> {
    thatsnuService.reset();
    window.location.reload();
}

const Settings: FC<SettingsProps> = () => (
  <div className="Settings">
      <button onClick={resetThatsnu}>Reset demo</button>
  </div>
);

export default Settings;
