import React, { FC } from 'react';
import './User.scss';

interface UserProps {}

const User: FC<UserProps> = () => (
  <div className="User">
    User Component
  </div>
);

export default User;
