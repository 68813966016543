import React, {FC, useEffect, useState} from 'react';
import './Customers.scss';
import {Customer} from '../../types';
import CustomerService from '../../services/CustomerService';
import LoggerService from '../../services/LoggerService';
import {Link} from 'react-router-dom';
import Spinner from '../Shared/Spinner/Spinner';
import {THATSNU_GROUPS} from '../../services/ThatsnuService';

interface CustomersProps {}

const Customers: FC<CustomersProps> = () => {

    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState<Array<Customer>>([]);

    useEffect(() => {
        CustomerService.getAll().then(reports => {
            setCustomers(reports);
        }).catch(e => {
            LoggerService.error(e);
        }).finally(() => {
            setLoading(false)
        });
    });

    return (
        <div className="Customers">
            { !loading ?
                <table className='table'>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Image</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Age</th>
                        <th>Address</th>
                        <th>Added at</th>
                    </tr>
                    </thead>
                    <tbody>
                    {customers.map((customer) => {
                        return <tr key={customer.id}>
                            <td>
                                <Link to={`/customer/${customer.id}`}>{customer.id}</Link>
                                { customer.isNew ?
                                    <span
                                        data-tnu-id={`${THATSNU_GROUPS.CUSTOMERS}.item.${customer.id}`}
                                        data-tnu-tooltip-text="This is a new customer!"
                                        data-tnu-expiration={customer.newUntil?.toISOString()}></span>
                                    : ''}
                            </td>
                            <td>
                                <img src={customer.avatar} alt="Customer" className='userImage'/>
                            </td>
                            <td>{customer.firstName}</td>
                            <td>{customer.lastName}</td>
                            <td>{customer.email}</td>
                            <td>{customer.age} years</td>
                            <td>{customer.address.street}, {customer.address.city}, {customer.address.country}</td>
                            <td>{customer.addedAt.toISOString()}</td>
                        </tr>;
                    })}
                    </tbody>
                </table> :
                <Spinner/>
            }
        </div>
    )
};

export default Customers;
