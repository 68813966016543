import React, {FC, useEffect, useState} from 'react';
import './Reports.scss';

import ReportsService from '../../services/ReportService';
import {Report} from '../../types';
import Spinner from '../Shared/Spinner/Spinner';
import LoggerService from '../../services/LoggerService';
import {Link} from 'react-router-dom';
import {THATSNU_GROUPS} from '../../services/ThatsnuService';

interface ReportsProps {}

const Reports: FC<ReportsProps> = () => {

    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState<Array<Report>>([]);

    useEffect(() => {
        ReportsService.getAll().then(reports => {
            setReports(reports);
        }).catch(e => {
            LoggerService.error(e);
        }).finally(() => {
            setLoading(false)
        });
    });

    return (
        <div className="Reports">
            { !loading ?
                <table className='table'>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {reports.map((report) => {
                        return <tr key={report.id}>
                            <td>
                                <Link to={`/report/${report.id}`}>{report.id}</Link>
                                { report.isNew ?
                                    <span
                                        data-tnu-id={`${THATSNU_GROUPS.REPORTS}.item.${report.id}`}
                                        data-tnu-text='New!'
                                        data-tnu-tooltip-text={`New report added: ${report.name}`}
                                        data-tnu-expiration={report.newUntil?.toISOString()}></span>
                                : ''}
                            </td>
                            <td>
                                {report.name}
                            </td>
                        </tr>;
                    })}
                    </tbody>
                </table> :
                <Spinner/>
            }
        </div>
    )
}

export default Reports;
