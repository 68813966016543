import React, {FC, useEffect, useState} from 'react';
import './Customer.scss';
import {Customer as CustomerType} from '../../../types';
import {useParams} from 'react-router-dom';
import LoggerService from '../../../services/LoggerService';
import CustomerService from '../../../services/CustomerService';
import Spinner from '../../Shared/Spinner/Spinner';
import {THATSNU_GROUPS} from '../../../services/ThatsnuService';

interface CustomerProps {}

const Customer: FC<CustomerProps> = () => {

    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState<CustomerType>();

    const { customerId: customerIdParam } = useParams();
    const customerId = parseInt(customerIdParam || '0') || 0;

    useEffect(() => {
        CustomerService.getCustomer(customerId).then(customer => {
            setCustomer(customer);
        }).catch(e => {
            LoggerService.error(e);
        }).finally(() => {
            setLoading(false)
        });
    });

    return <div className="Customer">
        {!loading ?
            <div>
                { !!customer ?
                    <div className='card'>
                        <div className='image'>
                            <img src={customer.avatar} alt=""/>
                        </div>
                        <div className='details'>
                            <div>{customer.firstName} {customer.lastName}</div>
                            <div><a href={'mailto:'+customer.email}>{customer.email}</a></div>
                            <div>Age: {customer.age}</div>
                            <div>Address: {customer.address.street}, {customer.address.city}, {customer.address.country}</div>
                            <div
                                data-tnu-id={`${THATSNU_GROUPS.CUSTOMERS}.addedAt`}
                                data-tnu-text="New!"
                                data-tnu-tooltip-text={`From now, you can see here on which date the customer were added!`}
                            >Added at: {customer.addedAt.toISOString()}</div>
                        </div>

                    </div>

                    : <div>Invalid report</div>}
            </div> :
            <Spinner/>
        }
    </div>
};

export default Customer;
