import React, {FC, useEffect, useState} from 'react';
import '@material-design-icons/font';
import './App.scss';
import {Link, Outlet, useLocation} from 'react-router-dom';
import Icon from './components/Shared/Icon/Icon';
import thatsnuService, {THATSNU_GROUPS, ThatsnuInitMethods} from './services/ThatsnuService';
import Spinner from './components/Shared/Spinner/Spinner';
import { currUser } from './mock/data';
import AnalyticsService from './services/AnalyticsService';

interface AppProps{}

const App: FC<AppProps> = () => {

    const [state, setState] = useState<{
        loading?: boolean
    }>({
        loading: true
    });

    useEffect(() => {
        async function load() {

            AnalyticsService.init();

            await thatsnuService.init({
                method: ThatsnuInitMethods.MODULE
            });
            setState({
                loading: false
            });
        }

        load();
    }, []);

    let html;

    const { pathname } = useLocation();
    const viewportTitle = (pathname.charAt(1).toUpperCase() + pathname.slice(2)) || 'Home';

    if (!state.loading) {
        html = <div className={'app'}>
            <header>
                <div className={'logo'}>
                    <Link to='/'><Icon icon='fa-brands fa-pied-piper'></Icon></Link>
                </div>
                <div className='title'>Customers portal</div>
                <div className={'userActions'}>
                    <span className='currUserName'>Hello, {currUser.firstName} {currUser.lastName}</span>
                    <Icon icon={'fa-solid fa-user'} clickable={true} />
                </div>
            </header>
            <article>
                <div className={'menu'}>
                    <nav>
                        <ul>
                            <li>
                                <Icon icon='fa-solid fa-house'/>
                                <Link to="/">Home</Link>
                            </li>
                            <li
                                data-tnu-id={THATSNU_GROUPS.CUSTOMERS}>
                                <Icon icon='fa-solid fa-users'></Icon>
                                <Link to="customers">Customers</Link>
                            </li>
                            <li
                                data-tnu-id={THATSNU_GROUPS.REPORTS}>
                                <Icon icon='fa-solid fa-chart-line'></Icon>
                                <Link to="reports">Reports</Link>
                            </li>
                            <li data-tnu-id={THATSNU_GROUPS.SETTINGS}
                                data-tnu-tooltip-text="New features has been added! ">
                                <Icon icon='fa-solid fa-gears'></Icon>
                                <Link to="settings">Settings</Link>
                            </li>
                        </ul>
                    </nav>

                    <div className='openSourceIcons'>

                        <div className="textHolder">open source project</div>

                        <div className="iconsHolder">
                            <a href="https://github.com/thatsnu/browser-sdk" target="_blank" title="Github">
                                <Icon icon="fa-brands fa-github"></Icon>
                            </a>
                            <a href="https://www.npmjs.com/package/@thatsnu/browser-sdk" target="_blank" title="NPM">
                                <Icon icon="fa-brands fa-npm"></Icon>
                            </a>
                        </div>
                    </div>

                </div>
                <div className={'viewport'}>
                    <h2>{viewportTitle}</h2>
                    <Outlet/>
                </div>
            </article>
            <footer>
                <Icon icon='fa-regular fa-copyright'></Icon>
                <span className='year'>{new Date().getFullYear()}</span>
            </footer>
        </div>
    } else {
        html = <Spinner/>;
    }

    return html;

}

export default App;
