export default {
    init() {

        const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

        if (!analyticsId) return;

        const script = document.createElement('script');
        script.async = true;
        document.getElementsByTagName('body')[0].append(script);
        script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];

        // @ts-ignore
        function gtag(){dataLayer.push(arguments);}
        // @ts-ignore
        gtag('js', new Date());
        // @ts-ignore
        gtag('config', analyticsId);
    }
}